export const events = [
	{
		hourStart: "10:30",
		hourEnd: "11:30",
		title: "Technical discussion including code reviews and plans for refactoring"
	},
	{
		hourStart: "13:00",
		hourEnd: "14:30",
		title: "Daily meeting"
	}, {
		hourStart: "13:00",
		hourEnd: "14:30",
		title: "Tasks estimations"
	},
	{
		hourStart: "13:30",
		hourEnd: "14:30",
		title: "Sprint review"
	}
];